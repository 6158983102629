$(document).ready(function() {
    //global
    common();
    new WOW().init();
    jumpMenu();
    loadGoogleFont();
    goToTop();
    
    //index
    bannerSlider();
    // productDetailSlider();
    // question();

    //blog
    blogSharing();
    // productSharing();
    // activitySharing();
   
});

function bannerSlider() {
    var bSlider = null;

    var $bannerSlider = $('#bannerSlider');
    if ($bannerSlider.length > 0) {

        if ($bannerSlider.find('.ms-view').length == 0) {
            setMasterSliderImageOnScreen($bannerSlider);

            try {
                bSlider = new MasterSlider();

                bSlider.control('timebar', {
                    insertTo: '#bannerSlider'
                });
                bSlider.control('bullets');

                // bSlider.control('circletimer', {
                //     autohide: false,
                //     overVideo: true,
                //     color: '#FFFFFF',
                //     radius: 4,
                //     stroke: 9
                // });

                bSlider.control('arrows', {
                    autohide: true
                });
                bSlider.setup('bannerSlider', {
                    width: 1920,
                    height: 1021,
                    minHeight: 400,
                    start: 1,
                    space: 0,
                    layout: 'fullwidth',
                    loop: true,
                    preload: 0,
                    instantStartLayers: false,
                    autoplay: true,
                    overPause: true,
                    view: "fadeBasic"
                });
                $('.master-skeleton-loader').remove();


            } catch (err) {
                console.error(err);

                removeErrorMasterSliderContainer($bannerSlider);
            }
        }


    }

}



function goToTop() {
    $(window).scroll(function() {
        if ($(this).scrollTop() > 300) {
            $('.scrollToTop').fadeIn();
        } else {
            $('.scrollToTop').fadeOut();
        }
    });

    //Click event to scroll to top
    $('.scrollToTop').click(function() {
        $('html, body').animate({ scrollTop: 0 }, 800);
        return false;
    });
}

function jumpMenu() {

    var jumpOffset = -$('.header').height();
    console.log('jumpMenu => offset:' + jumpOffset);

    //選單跳段落
    $('.header a').click(function (evt) {

        console.log('click menu link:' + this.hash);

        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') || location.hostname == this.hostname) {
            jumpSection(this.hash, jumpOffset, 500);
            // return false;
        }
        
    });

    /*才能避免slider產生後的高度讓跳cut不正確*/
    var jumpHash = location.hash;
    //超連結跳段落
    if (jumpHash !== '') {
        var newjumpHash = jumpHash.replace(/\//, '');
        console.log('detect jump from link url:');
        Pace.on('done', function () {
            jumpSection(newjumpHash, jumpOffset, 500);
            // return false;
        });
    }

}


function question() {

    if (window.JUMP_DIR != '') {
        var $q4Elem = $("#" + window.JUMP_DIR);
        if ($q4Elem.length > 0) {
            $('html, body').animate({ scrollTop: $q4Elem.offset().top }, 2000);

        }
    }

    var $questions =
        $('.questions');

    var $questionsTitle =
        $questions.find('li > h3');

    $questionsTitle.prepend('<i class="fa fa-caret-right" aria-hidden="true"></i> ');

    $questionsTitle.click(function(e) {

        var $expand = $(this).find('i');
        // console.log($expand);
        if ($expand.hasClass('fa-caret-right')) {
            //open
            var $answer =
                $(this).next();
            $answer.show();

            $expand.removeClass('fa-caret-right')
                .addClass('fa-caret-down');
        } else {
            //close
            var $answer =
                $(this).next();
            $answer.hide();

            $expand.removeClass('fa-caret-down')
                .addClass('fa-caret-right');
        }

    });

    var $answers =
        $questions.find('li > div');
    $answers.hide();

}

function blogSharing() {
    initJsSocial($('#blogSharing'));
}

function productSharing() {
    initJsSocial($('#productSharing'));
}

function activitySharing() {
    initJsSocial($('#activitySharing'));
}

function initJsSocial($targetElem) {
    if ($targetElem.length > 0) {
        $targetElem.jsSocials({
            shares: ["twitter", "facebook", "linkedin"]
        });
    }
}

function loadGoogleFont() {
    WebFont.load({
        timeout: 2000,
        google: {
            families: ['Noto Sans TC:100,300,500,600,800&display=swap', 'Roboto&display=swap']
        },
        custom: {
            // families: [
            //     'FontAwesome',
            // ]
        },

        fontloading: function(familyName, fvd) {
            console.log('fontloading:' + familyName);
        },
        fontactive: function(familyName, fvd) {
            console.log('fontactive:' + familyName);
        },
        fontinactive: function(familyName, fvd) {
            console.log('fontinactive:' + familyName);
        }
    });
}