$(function () {
    theme();
    owl();
    faq();
    animateNumber();
    bgVideo();
});

function theme(){
    var $body = $('body');
    var $header = $('.header');
    var $menuNavToggle = $('.menu-nav-toggle');
    var $hamburgerToggle = $('.hamburger-toggle');
    var $dropsubBtn = $('.dropsub-btn');
    var $submenu = $('.submenu');
    var $removeMask = $('.remove-mask');

    $dropsubBtn.on('click', function(){
        $(this).parent().toggleClass('active');
        $submenu.slideToggle();
    });
    $menuNavToggle.on('click',function(){
        $body.toggleClass('active');
        $header.toggleClass('active');
    });
    $hamburgerToggle.on('click',function(){
        $body.toggleClass('active');
        $header.toggleClass('active');
        $submenu.hide();
    });
    $removeMask.on('click',function(){
        $body.toggleClass('active');
        $header.toggleClass('active');
    });

    $header.stickyHeader({
        hideAfter: 10,
        showAt: 0,
        animation: 'slide',
        speed: 200,
        onFixedHeight:function(hOfHeader){
            //初始化就計算要位移的間距
            //本專案不使用
            // $('main').css({
            //     'margin-top':hOfHeader+'px'
            // });
        }
    });
}

function faq() {
    var $faqQuestion = $(".item-question");
    var $faqAnswer = $(".item-answer");
    $faqAnswer.hide();
    $faqQuestion.on("click", function(){
        $(this).parent().find($faqAnswer).slideToggle(300);
    })
}

function animateNumber() {
    var $animate_number = $('.animate-number');

    if ($animate_number.length > 0) {
        $animate_number.each(function () {
            var $this = $(this);
            $this.data('isAnimate', false);

            if (!$this.hasClass('animate-stop')) {
               
                $this.on('inview', function (event, isInView) {
                    if (isInView) {
                        if ($this.data('isAnimate') == false) {
                            $this.animateNumber({
                                number: $this.attr("data-value")
                            }, 750);
                            $this.addClass('animate-stop');
                            $this.data('isAnimate', true);
                        }

                    }
                });
            }
        });
    }
}

function owl() {

    lazyloadOwl($('.newsinfo-item'),{
        autoplay: true,
        dots: true,
        loop: true,
        margin: 20,
        nav: false,
        center:true,
        autoHeight:true,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1
            },
            992: {
                items: 3
            }
        }
    });

    lazyloadOwl($('.brands-carousel'),{
        autoplay: true,
        dots: true,
        loop: true,
        margin: 20,
        nav: false,
        center:true,
        autoHeight:true,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1
            },
            992: {
                items: 5
            }
        }
    });
   
    lazyloadOwl($('.news-carousel'),{
        autoplay:true,
        loop:true,
        margin:20,
        responsiveClass:true,
        responsive:{
            0:{
                items:1,
                nav:false
            },
            576:{
                items:2,
                nav:false
            },
            992:{
                items:3
            }
        }
    });

    
    // if ($(window).width() <= 992) {
         
    //     $('.newsinfo-item').owlCarousel({
    //         autoplay:true,
    //         loop:true,
    //         margin:20,
    //         responsiveClass:true,
    //         responsive:{
    //             0:{
    //                 items:1
    //             },
    //             576:{
    //                 items:2
    //             }
    //         }
    //     });
    // }
}

function lazyloadOwl($owl, newOpts, cb) {
    console.log('lazyloadOwl');
    if ($owl.length > 0) {
       
        var defaultOpts = {
            // loop: true,
            margin: 10,
            // responsiveClass: true,
            animateOut: 'fadeOut',
            animateIn: 'fadeIn',
            slideBy: 1,
           
        };

        if (typeof (newOpts) != 'undefined')
            $.extend(true, defaultOpts, newOpts);

        $owl.owlCarousel(defaultOpts);
       
        owlSliderEnableLazyload($owl);

        if (typeof (cb) != 'undefined') {
            cb($owl);
        }
    }

}

function owlSliderEnableLazyload($owlSlider) {
    console.log('owlSliderEnableLazyload');
    if ($owlSlider.length > 0) {
        var isFired = false;

        var $owlImages = $owlSlider.find('img');
        $owlSlider.on('inview', function (event, isInView) {
            if (isFired == false) {
                isFired = true;
                startLoadImages($owlImages);
            }
        });
    }

}

function bgVideo(){
    // Pace.on('done', function () {
        var $bgYoutube = $('.bg-youtube');
        var isFired = false;
        if( $bgYoutube.length>0){
            $bgYoutube.on('inview', function (event, isInView) {
                if (isFired == false) {
                    isFired = true;
                    $bgYoutube.on('video-background-play', function(event) {
                        console.log('video-background-play'); // the video instance object
                        console.log(event.originalEvent.detail); // the video instance object
                    });
                    $bgYoutube.youtube_background();
                }
            });
        }
      
       
    // });
}